var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

var flexsliderLoad = require('flexslider'),
	flexslider = require('../modules/slider')
	rellaxInit = require('rellax');

global.rel = rellaxInit;

page_home = {
	load: function(){
		this.flexslider();
		// this.parallaxStart();
		// initializeGmapVe();
		// initializeGmapMe();
  //   	initializeGmapMi();
	},
	resize: function(){
		this.flexslider();
	},
	flexslider: function(){
		slider = $('.homeBlock__gallery');
		slider.each(function() {
			flexslider.visualFull(slider);
		});
	},
	parallaxStart: function(){
		if ($(window).outerWidth() > 767) {
			var rellax = new rel('.rellax');
		}
	},
}

$(window).on('load', function(){
	page_home.load();
})
$(window).on('resize', function(){
	page_home.resize();
})