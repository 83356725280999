module.exports = {
	init: function(){
		this.visualFull('.flexslider');
	},
	visualFull: function (item) {
		if($(item).length===0 ) return false;
		$(item).flexslider({
			animation: 'slide',
			direction: 'horizontal',
			directionNav: true,
			controlNav: false,
			animationLoop:  true,
			pauseOnAction:  false,
			pauseOnHover:   true,
			animationSpeed:  1000,
			slideshowSpeed: 7500,
			after: function(slider){
				siteGlobal.lazyLoad();
			}
		});
	},
	carousel: function (item) {
		function getGridSize() {
			return (window.innerWidth < 600) ? 1 :
			(window.innerWidth < 900) ? 2 : 3;
		}

		if($(item).length===0 ) return false;
		$(item).flexslider({
			animation: 'slide',
			direction: 'horizontal',
			animationLoop: false,
			directionNav: true,
			controlNav: false,
			animationLoop:  true,
			itemWidth: 210,
			itemMargin: 25,
			minItems: 1,
      		maxItems: getGridSize(),
      		after: function(slider){
				siteGlobal.lazyLoad();
			}
		});
	}
}


